import React from "react";
import "./style.css";
import { colors } from "../../styles/colors";

const HoursComponent = ({
  activeHorario,
  horarios,
  seleccionarHorarios,
  diaSeleccionado,
}) => {
  const currentTime = new Date();
  const currentDate = currentTime.toLocaleDateString(); // Obtener la fecha actual en formato local (por ejemplo, "dd/mm/yyyy")
  const currentHours = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  // Función que verifica si el horario ya ha pasado (solo si es el día de hoy)
  const isPastHorario = (horario) => {
    const [hours, minutes] = horario.split(":").map(Number);
    if (hours < currentHours) return true;
    if (hours === currentHours && minutes < currentMinutes) return true;
    return false;
  };

  return (
    <>
      <div className={activeHorario ? "container-hours" : "displayNone"}>
        <div className="subcontainer-hours">
          {activeHorario && horarios !== undefined
            ? horarios.map((info, i) => {
                // Verifica si el día seleccionado es el día actual
                const isToday =
                  diaSeleccionado?.toLocaleDateString() === currentDate;

                // Solo aplica la verificación de horarios pasados si es el día de hoy
                const isPast = isToday ? isPastHorario(info.horario) : false;

                return (
                  <div key={i}>
                    <div
                      className="container-buttons-option-hours"
                      key={info.horario}
                      style={{
                        border: `1px solid ${
                          info.available && !isPast
                            ? colors.primary.primary
                            : colors.purple.lightPurple
                        }`,
                        color: `${
                          info.available && !isPast
                            ? "#FFF"
                            : colors.purple.lightPurple
                        }`,
                        cursor: `${
                          info.available && !isPast ? "pointer" : "auto"
                        }`,
                        backgroundColor: `${
                          info.available && !isPast
                            ? colors.primary.primary
                            : "#FFF"
                        }`,
                      }}
                    >
                      <div
                        onClick={() =>
                          info.available && !isPast
                            ? seleccionarHorarios(info.horario)
                            : null
                        }
                      >
                        {info.horario}
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </>
  );
};

export default HoursComponent;
