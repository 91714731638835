import React, { useEffect, useState } from "react";
import "../styles/auta.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-day-picker/lib/style.css";
import "animate.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { getDiasMes, getHorarios } from "../services/turnos";
import { validarPatente } from "../services/form";
import { ModalLoading } from "./ModalLoading";
import { addWeeks, isAfter, isBefore } from "date-fns";
import { ContainerAppComponent } from "./ContainerAppComponent";
import { TitleCardComponent } from "./TitleCardComponent";
import { CardComponent } from "./CardComponent";
import { colors } from "../styles/colors";
import { FaCheck } from "react-icons/fa6";
import SelectComponent from "./Select/SelectComponent";
import CalendarComponent from "./Calendar/CalendarComponent";
import HoursComponent from "./Hours/HoursComponent";
import LocationComponent from "./Location/LocationComponent";
import PlateComponent from "./Plate/PlateComponent";
import ButtonSendComponent from "./Buttons/ButtonSendComponent";
import { redirectUser } from "../services/appointment";
import { Spinner } from "react-bootstrap";

export const ContainAppointment = () => {
  const { data, loading, status } = useSelector((state) => state.turnosReducer);
  const { email } = useSelector((state) => state.authReducer);

  const [activeCalendar, setactiveCalendar] = useState(false);
  const [activeHorario, setactiveHorario] = useState(false);
  const [activeUbication, setActiveUbication] = useState(false);
  const [diaSeleccionado, setdiaSeleccionado] = useState("");
  const [horarioSeleccionado, sethorarioSeleccionado] = useState("");
  const [ubication, setUbication] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [horarios, sethorarios] = useState();
  const [daysDisabledOneMonth, setDaysDisabledOneMonth] = useState();
  const [daysDisabledTwoMonth, setDaysDisabledTwoMonth] = useState();
  const [validandoAppointment, setvalidandoAppointment] = useState();
  const [valuePatente, setvaluePatente] = useState("");
  const [patenteValida, setPatenteValida] = useState(false);
  const [allDaysDisabled, setallDaysDisabled] = useState();
  const [conc, setconc] = useState();
  const [modal, setmodal] = useState(false);
  const [isTake, setisTake] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { deal_id } = useParams();

  const handleSelectCompany = (location, company) => {
    setUbication(location);
    setCompanySelected(company);
    setActiveUbication(false);
    sethorarioSeleccionado("");
    setdiaSeleccionado("");
  };

  const showCalendar = () => {
    setactiveCalendar(!activeCalendar);
    setActiveUbication(false);
    setactiveHorario(false);
  };
  const showHorario = () => {
    setactiveHorario(!activeHorario);
    setactiveCalendar(false);
    setActiveUbication(false);
  };
  const showUbication = () => {
    setActiveUbication(!activeUbication);
    setactiveHorario(false);
    setactiveCalendar(false);
  };

  useEffect(() => {
    if (pathname.includes("toma")) {
      setisTake(true);
      if (data !== undefined) {
      } else {
        return redirectUser(
          "pretake",
          deal_id,
          setvalidandoAppointment,
          navigate,
          dispatch
        );
      }
    } else {
      setisTake(false);
      if (data !== undefined) {
      } else {
        return redirectUser(
          "check",
          deal_id,
          setvalidandoAppointment,
          navigate,
          dispatch
        );
      }
    }
  }, []);

  const handleInputChange = (e) => {
    const inputPatente = e.target.value.toUpperCase();
    setvaluePatente(inputPatente);
    setPatenteValida(validarPatente(inputPatente));
  };

  const seleccionarDia = (data) => {
    const diaConvertido = data.getUTCDate();
    const mesConvertido = data.getMonth();

    const esEsteMes = mesConvertido === new Date().getMonth();

    let found;
    if (esEsteMes) {
      found = daysDisabledOneMonth.find((dias) => dias === diaConvertido);
    } else {
      found = daysDisabledTwoMonth.find((dias) => dias === diaConvertido);
    }

    if (!found) {
      setdiaSeleccionado(data);
      setactiveCalendar(!activeCalendar);
    }
  };

  useEffect(() => {
    getHorarios(diaSeleccionado, sethorarios, companySelected);
  }, [diaSeleccionado]);

  const seleccionarHorarios = (data) => {
    sethorarioSeleccionado(data);
    setactiveHorario(!activeHorario);
  };

  useEffect(() => {
    getDiasMes(
      setDaysDisabledOneMonth,
      setDaysDisabledTwoMonth,
      setallDaysDisabled,
      companySelected
    );
  }, [companySelected]);

  const fechaActual = new Date();
  const cuatroDiasEnMilisegundos = 4 * 24 * 60 * 60 * 1000;

  // Función para obtener el número de días en un mes
  const obtenerDiasEnElMes = (year, month) => {
    // El mes es 0-indexado, por lo que pasamos el siguiente mes
    return new Date(year, month + 1, 0).getDate(); // Retorna el último día del mes siguiente
  };

  // Fecha actual + 4 días
  const fechaSumada = new Date(
    fechaActual.getTime() + cuatroDiasEnMilisegundos
  );

  // Obtener los días en el mes siguiente
  const diasEnMesSiguiente = obtenerDiasEnElMes(
    fechaSumada.getFullYear(),
    fechaSumada.getMonth() + 1
  );

  // Convertir los días del mes siguiente a milisegundos
  const mesSiguienteEnMilisegundos = diasEnMesSiguiente * 24 * 60 * 60 * 1000;

  useEffect(() => {
    if (allDaysDisabled !== undefined) {
      const diasDisabled = allDaysDisabled.map(
        (info) => new Date(2024, info.numberMonth, info.dia)
      );
      const befores = [{ before: new Date() }];
      const conca = diasDisabled.concat(befores);

      if (!email && !isTake) {
        const afters = [
          {
            from: fechaSumada,
            to: new Date(fechaSumada.getTime() + mesSiguienteEnMilisegundos),
          },
        ];
        const conca2 = conca.concat(afters);
        setconc(conca2);
      } else {
        setconc(conca);
      }
    }
  }, [allDaysDisabled, email, isTake]);

  return (
    <ContainerAppComponent>
      {validandoAppointment === undefined &&
      data !== "cancelar" &&
      data !== "modificar" ? (
        <div className="d-flex align-items-center justify-content-center flex-column p-4 container-loading">
          <p className="text-title-modal">Espere un momento ...</p>
          <Spinner
            animation="border"
            role="status"
            style={{
              color: " #7340D8",
            }}
            className="mt-2"
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <CardComponent>
          <div className="subcontainer-card-turnos">
            <TitleCardComponent
              text={
                data === "cancelar"
                  ? `¿Desea agendar una nueva ${
                      isTake ? "toma" : "inspección"
                    }?`
                  : data === "modificar"
                  ? `Modificar turno - Agendar ${
                      isTake ? "toma" : "inspección"
                    }`
                  : isTake
                  ? "Agendar toma"
                  : "Agendar inspección"
              }
            />
            <div className="d-flex flex-column ps-1 pe-1 mt-3">
              <div className="d-flex flex-column">
                {!isTake ? (
                  <div
                    className="container-date d-flex align-items-start justify-content-start"
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        height: activeUbication ? "130px" : "60px",
                        width: "4px",
                        backgroundColor:
                          ubication !== ""
                            ? colors.purple.darkPurple
                            : colors.purple.ultraLightPurple,
                      }}
                      className="ms-2 me-4 d-flex"
                    >
                      <div className="circle-step"></div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <>
                        <SelectComponent
                          label={"Punto de inspección"}
                          value={ubication}
                          show={showUbication}
                          active={activeUbication}
                          placeholder={"Seleccione un punto de inspección"}
                        />
                        <LocationComponent
                          active={activeUbication}
                          handleSelectCompany={handleSelectCompany}
                        />
                      </>
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className="container-date d-flex align-items-start justify-content-start"
                style={{ height: "100%" }}
              >
                <div
                  style={{
                    height: activeCalendar ? "330px" : "60px",
                    width: "4px",
                    backgroundColor:
                      (!isTake && ubication !== "" && diaSeleccionado) ||
                      (isTake && diaSeleccionado !== "")
                        ? colors.purple.darkPurple
                        : colors.purple.ultraLightPurple,
                  }}
                  className="ms-2 me-4 d-flex"
                >
                  <div
                    className="circle-step"
                    style={{
                      backgroundColor:
                        ubication !== "" || isTake
                          ? colors.purple.purple
                          : colors.purple.lightPurple,
                    }}
                  ></div>
                </div>
                <div style={{ width: "100%" }}>
                  <SelectComponent
                    label={"Fecha"}
                    value={diaSeleccionado}
                    show={ubication !== "" || isTake ? showCalendar : null}
                    active={activeCalendar}
                    placeholder={"Seleccione una fecha"}
                    isCalendar={true}
                    queryLabel={
                      !isTake
                        ? {
                            color:
                              ubication !== ""
                                ? colors.purple.purple
                                : colors.purple.lightPurple,
                          }
                        : { color: colors.purple.purple }
                    }
                  />
                  <CalendarComponent
                    activeCalendar={activeCalendar}
                    conc={conc}
                    fechaActual={fechaActual}
                    sethorarioSeleccionado={sethorarioSeleccionado}
                    addWeeks={addWeeks}
                    isBefore={isBefore}
                    email={email}
                    isTake={isTake}
                    isAfter={isAfter}
                    seleccionarDia={seleccionarDia}
                    companySelected={companySelected}
                  />
                </div>
              </div>
              <div className="d-flex flex-column">
                <div
                  className="container-date d-flex align-items-start justify-content-start"
                  style={{ height: "100%" }}
                >
                  <div
                    style={{
                      height: activeHorario ? "300px" : "60px",
                      width: "4px",
                      backgroundColor:
                        (!isTake &&
                          ubication !== "" &&
                          diaSeleccionado !== "" &&
                          horarioSeleccionado !== "") ||
                        (isTake &&
                          diaSeleccionado !== "" &&
                          horarioSeleccionado !== "")
                          ? colors.purple.darkPurple
                          : colors.purple.ultraLightPurple,
                    }}
                    className="ms-2 me-4 d-flex"
                  >
                    <div
                      className="circle-step"
                      style={{
                        backgroundColor:
                          (ubication !== "" && diaSeleccionado !== "") ||
                          (isTake && diaSeleccionado !== "")
                            ? colors.purple.purple
                            : colors.purple.lightPurple,
                      }}
                    ></div>
                  </div>
                  <div style={{ width: "100%" }}>
                    <SelectComponent
                      label={"Horario"}
                      value={horarioSeleccionado}
                      show={diaSeleccionado !== "" ? showHorario : null}
                      active={activeHorario}
                      placeholder={"Seleccione un horario"}
                      queryLabel={{
                        color:
                          diaSeleccionado !== ""
                            ? colors.purple.purple
                            : colors.purple.lightPurple,
                      }}
                    />
                    <HoursComponent
                      activeHorario={activeHorario}
                      horarios={horarios}
                      seleccionarHorarios={seleccionarHorarios}
                      diaSeleccionado={diaSeleccionado}
                    />
                  </div>
                </div>
              </div>

              {!isTake ? (
                <div className="d-flex flex-column">
                  <div
                    className="container-date d-flex align-items-start justify-content-start"
                    style={{ height: "100%" }}
                  >
                    <div
                      style={{
                        height: "100px",
                        width: "4px",
                        backgroundColor:
                          patenteValida &&
                          diaSeleccionado &&
                          ubication &&
                          horarioSeleccionado !== ""
                            ? colors.purple.darkPurple
                            : colors.purple.ultraLightPurple,
                      }}
                      className="ms-2 me-4 d-flex"
                    >
                      <div
                        className="circle-step"
                        style={{
                          backgroundColor:
                            diaSeleccionado &&
                            horarioSeleccionado &&
                            ubication !== ""
                              ? colors.purple.purple
                              : colors.purple.lightPurple,
                        }}
                      ></div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <PlateComponent
                        valuePatente={valuePatente}
                        handleInputChange={handleInputChange}
                        diaSeleccionado={diaSeleccionado}
                        horarioSeleccionado={horarioSeleccionado}
                        locationSelect={ubication}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="d-flex align-items-center justify-content-between">
                <div
                  className="circle-step d-flex align-items-center justify-content-center"
                  style={{
                    zIndex: "3",
                    position: "relative",
                    marginTop: "-22px",
                    width: "30px",
                    height: "30px",
                    backgroundColor:
                      patenteValida ||
                      (isTake && diaSeleccionado && horarioSeleccionado)
                        ? colors.purple.purple
                        : colors.purple.lightPurple,
                  }}
                >
                  <FaCheck
                    color={
                      patenteValida ||
                      (isTake &&
                        diaSeleccionado &&
                        horarioSeleccionado &&
                        ubication !== "")
                        ? "#FFFFFF"
                        : "#E8E1EF"
                    }
                  />
                </div>
                <div
                  style={{ width: "100%" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <ButtonSendComponent
                    locationSelect={ubication}
                    patenteValida={patenteValida}
                    diaSeleccionado={diaSeleccionado}
                    horarioSeleccionado={horarioSeleccionado}
                    isTake={isTake}
                    valuePatente={valuePatente}
                    deal_id={deal_id}
                    navigate={navigate}
                    email={email}
                    dispatch={dispatch}
                    setmodal={setmodal}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardComponent>
      )}
      <ModalLoading
        modal={modal}
        setmodal={setmodal}
        loading={loading}
        status={status}
      />
    </ContainerAppComponent>
  );
};
